import SdgsLogo from "./SdgsLogo";

const ListSdgsLogo = () => {
  return (
    <div className="mb-16 mt-20 text-center flex flex-col items-center justify-center">
      <h2 className="text-center text-2xl font-bold text-primary-black w-[40rem] leading-10">
        Target Kontribusi Kami
      </h2>

      {/* list SDGs Icon*/}
      <div className="flex flex-wrap items-center justify-center mt-14">
        <SdgsLogo sdgNumber="3" />
        <SdgsLogo sdgNumber="4" />
        <SdgsLogo sdgNumber="11" />
      </div>

      <div className="flex flex-wrap items-center justify-center mt-14">
        <SdgsLogo sdgNumber="15" />
        <SdgsLogo sdgNumber="16" />
        <SdgsLogo sdgNumber="17" />
      </div>
      {/* end of list SDGs Icon*/}
    </div>
  );
};

export default ListSdgsLogo;
