import { useEffect, useRef } from "react";

interface TopicTitleProps {
  sendTitleRef: (ref: HTMLElement | null) => void;
  title: string;
}

const TopicTitle = ({ title, sendTitleRef }: TopicTitleProps) => {
  const ref = useRef(null);

  useEffect(() => {
    sendTitleRef(ref.current);
  }, [sendTitleRef]);

  return (
    <h2
      ref={ref}
      className="topic-title cursor-pointer font-bold text-[17px] leading-8 text-primary-black"
    >
      {title}
    </h2>
  );
};

export default TopicTitle;
