import { ReactComponent as HomeIcon } from "../../assets/HomeIcon.svg";
import { Link } from "react-router-dom";

const HomeLink = () => {
  return (
    <Link to={"/"} className="">
      <div className="rounded-full pl-6 pr-4 py-[0.5rem]">
        <HomeIcon className="w-[1.5rem]" />
      </div>
    </Link>
  );
};

export default HomeLink;
