import { Link } from "react-router-dom";

const AboutUsLink = () => {
  return (
    <Link to={"/about-us"}>
      <svg
        className="ml-2"
        width="28"
        height="26"
        viewBox="0 0 31 27"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M27.0141 3.39035C30.0287 6.40496 30.0287 11.2926 27.0141 14.3072L15.3214 25.9999L15.241 25.9195L15.1605 26L3.46784 14.3073C0.453235 11.2927 0.453237 6.40505 3.46784 3.39045C6.48245 0.375841 11.3701 0.375843 14.3847 3.39045L14.7898 3.79559C15.039 4.04471 15.4429 4.04471 15.692 3.79559L16.0972 3.39035C19.1118 0.375742 23.9995 0.375743 27.0141 3.39035Z"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </Link>
  );
};

export default AboutUsLink;
