import { ReactComponent as ThreeDots } from "../../../../assets/ThreeDots.svg";

interface MoreFilterButtonProps {
  handleOpenMoreFilter: () => void;
}

const MoreFilterButton = ({ handleOpenMoreFilter }: MoreFilterButtonProps) => {
  return (
    <button
      onClick={handleOpenMoreFilter}
      className="mt-4 md:mt-4 lg:mt-3 mx-2 text-3xl px-3 py-3 cursor-pointer text-[#41C9E2] bg-[#41C9E2]/[0.08] hover:bg-[#41C9E2]/[0.24] rounded-full"
    >
      <ThreeDots className="w-6" />
    </button>
  );
};

export default MoreFilterButton;
