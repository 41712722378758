import Skeleton from "react-loading-skeleton";
// import SaveIcon from "./Saveicon";
import { Topic } from "../../../../variable interface/Topic";

interface TopicTitleProps {
  topic: Topic | undefined;
}

const TopicTitle = ({ topic }: TopicTitleProps) => {
  return (
    <div className="flex flex-col justify-start items-start sm:justify-center sm:items-center mb-8 sm:mb-12 mt-14 sm:mt-12">
      <h1 className="font-bold text-2xl md:text-3xl ml-2 mr-5 sm:mx-2 text-primary-black">
        {topic?.judul || <Skeleton width={248} borderRadius={24} />}
      </h1>

      <div className="ml-2 mr-5 sm:mx-2 mt-4 flex flex-wrap">
        <div className="mt-2.5 text-sm px-7 py-2 rounded-3xl text-[#425466] bg-white border-solid border-[1px] border-[#425466]">
          {topic?.hadits?.length} Hadits
        </div>
      </div>

      {/* redundant component => maybe later :) */}
      {/* {topic && <SaveIcon topic={topic} />} */}
    </div>
  );
};

export default TopicTitle;
