import CountUp from "react-countup";

const ProjectProgress = () => {
  return (
    <div className="my-24 text-center flex flex-col items-center justify-center">
      <h2 className="text-center text-2xl font-bold text-primary-black w-[40rem] leading-10">
        Proses Proyek Kami
      </h2>

      <div className="flex flex-col justify-evenly w-10/12 lg:w-8/12 sm:flex-row justify-center items-center text-center text-secondary-black">
        <div className="mt-16">
          <h2 className="font-bold text-6xl">
            <CountUp enableScrollSpy={true} end={60} duration={1} />+
          </h2>
          <p className="mt-3">Topik Dibahas</p>
        </div>

        <div className="mt-16">
          <h2 className="font-bold text-6xl">
            <CountUp enableScrollSpy={true} end={150} duration={1} />+
          </h2>
          <p className="mt-3">Hadits Disampaikan</p>
        </div>
      </div>
    </div>
  );
};

export default ProjectProgress;
