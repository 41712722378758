import CardMission from "./CardMission";

const ListCardMission = () => {
  return (
    <div className="flex items-center justify-center mt-6 mb-12 flex-wrap">
      <CardMission
        title="Memiliki Kepedulian Sosial yang Baik"
        illustration="illustration12"
      />
      <CardMission
        title="Menjadi Seseorang yang Profesional"
        illustration="illustration11"
      />
      <CardMission
        title="Memiliki Perilaku yang Beradap"
        illustration="illustration42"
      />
    </div>
  );
};

export default ListCardMission;
