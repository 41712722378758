import { useEffect, useState } from "react";
import BackLink from "../../../../components/navigation/BackLink";
import NavLogo from "../../../../components/navigation/NavLogo";
import SavedItemsLink from "../../../../components/navigation/SavedItemsLink";

const Navigation = () => {
  const [show, setShow] = useState<boolean>(true);
  const [lastScrollY, setLastScrollY] = useState<number>(0);

  useEffect(() => {
    const controlNavbar = () => {
      if (window.scrollY > lastScrollY && window.scrollY > 0) {
        // if scroll down hide the navbar
        setShow(false);
      } else {
        // if scroll up show the navbar
        setShow(true);
      }
      // remember current page location to use in the next move
      setLastScrollY(window.scrollY);
    };

    window.addEventListener("scroll", controlNavbar);

    // cleanup function
    return () => {
      window.removeEventListener("scroll", controlNavbar);
    };
  }, [lastScrollY]);

  return (
    <nav
      className={`${
        show ? "translate-y-[0%]" : "translate-y-[-100%]"
      } h-[4.25rem] duration-300 fixed top-0 left-1/2 translate-x-[-50%] z-10 bg-[#1AA5F2] drop-shadow-primary-blue mx-auto my-4 px-7 py-2 rounded-3xl flex justify-between items-center w-11/12 md:8/12 lg:w-7/12`}
    >
      <div className="nav-links flex items-center space-x-1">
        <BackLink />
        <SavedItemsLink />
      </div>
      <NavLogo />
    </nav>
  );
};

export default Navigation;
