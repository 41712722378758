import { useState, useEffect } from "react";
import { ReactComponent as HomeIcon } from "../../assets/HomeIcon.svg";
import { Link } from "react-router-dom";

const HomeFloatButton = () => {
  const [show, setShow] = useState<boolean>(true);
  const [lastScrollY, setLastScrollY] = useState<number>(0);

  useEffect(() => {
    const controlFloatButton = () => {
      const minScrollY = 0;

      if (minScrollY + window.scrollY <= 100) {
        // if min scroll Y
        setShow(true);
      } else if (window.scrollY > lastScrollY) {
        // if scroll down hide the navbar
        setShow(false);
      } else {
        // if scroll up show the navbar
        setShow(true);
      }

      // remember current page location to use in the next move
      setLastScrollY(window.scrollY);
    };

    window.addEventListener("scroll", controlFloatButton);

    // cleanup function
    return () => {
      window.removeEventListener("scroll", controlFloatButton);
    };
  }, [lastScrollY]);

  return (
    <Link
      to={"/"}
      className={`${
        show ? "top-8" : "top-[-100%]"
      } z-10 duration-300 fixed right-8 sm:right-20 bg-[#1AA5F2] rounded-full px-4 py-[0.72rem] drop-shadow-primary-blue hover:bg-[#199AE1]`}
    >
      <HomeIcon className="w-5 mt-[-1px] mr-[-1px]" />
    </Link>
  );
};

export default HomeFloatButton;
