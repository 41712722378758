interface LineSeparatorProps {
  marginYClass: string;
}

const LineSeparator = ({ marginYClass }: LineSeparatorProps) => {
  return (
    <div
      id="line-separator"
      className={`${marginYClass} h-[1px] w-11/12 bg-[#C3E1F2] mx-auto  rounded-xl`}
    ></div>
  );
};

export default LineSeparator;
