import { QuotesHadits } from "../../Quotes";
import SavedQuotes from "./SavedQuotes";
import PageTitle from "../../../../components/ui/PageTitle";
import { ReactComponent as CrossIcon } from "../../../../assets/CrossIcon.svg";
import { useEffect, useState } from "react";
import { savedHaditsQuotesDB } from "../../../../helper/quotesIndexDB";
import EmptyState from "../../../../components/ui/EmptyState";

interface ListSavedQuotesProps {
  isOpen: boolean;
  handleOpen: () => void;
  showSavedQuotes: (hadits: QuotesHadits) => void;
}

// create dexie db
const db = savedHaditsQuotesDB();

const ListSavedQuotes = ({
  isOpen,
  handleOpen,
  showSavedQuotes,
}: ListSavedQuotesProps) => {
  const [savedHadits, setSavedHadits] = useState<QuotesHadits[]>([]);

  useEffect(() => {
    // load data from indexDB
    const loadDataFromIndexDB = async () => {
      try {
        const data: QuotesHadits[] = await db
          .table("savedHaditsQuotes")
          .toArray();
        setSavedHadits(data);
      } catch (error) {
        console.log(error);
      }
    };
    loadDataFromIndexDB();
  }, [isOpen]);

  return (
    <div
      className={`${
        isOpen ? "translate-y-[0%]" : "translate-y-[100%]"
      } fixed duration-300 flex flex-col justify-center items-center w-full h-full bg-white z-30`}
    >
      <div
        className={`${
          isOpen ? "flex" : "hidden"
        } border-solid border-b-2 border-[#C3E1F2] px-1 flex justify-between items-center pb-2 pt-4 sm:text-base w-11/12 sm:w-10/12 lg:w-6/12 mt-2`}
      >
        <PageTitle classPosition="justify-start" title="Hadits yang disimpan" />
        {/* close button */}
        <button
          onClick={handleOpen}
          className="px-3 py-3 cursor-pointer bg-[#1AA5F2]/[0.12] hover:bg-[#1AA5F2]/[0.24] rounded-full"
        >
          <CrossIcon className="w-4 h-4" />
        </button>
      </div>

      <div
        className={`${
          isOpen ? "flex" : "hidden"
        } list-saved-quotes flex flex-col items-center mt-4 pt-1 h-full pb-8 overflow-y-auto`}
      >
        {/* card */}

        {savedHadits.length > 0 ? (
          savedHadits.map((hadits) => (
            <SavedQuotes
              hadits={hadits}
              showSavedQuotes={(hadits) => showSavedQuotes(hadits)}
              key={hadits.id}
            />
          ))
        ) : (
          <EmptyState
            illustration="emptyState1"
            text="Belum ada hadits yang disimpan.."
          />
        )}
      </div>
    </div>
  );
};

export default ListSavedQuotes;
