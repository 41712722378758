import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";
// import required modules
import { Pagination } from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import Navigation from "./components/navigation/Navigation";
import IllustrationBox from "./components/illustration/IllustrationBox";
import OnBoardingText from "./components/text/OnBoardingText";
import OnBoardingButtons from "./components/buttons/OnBoardingButtons";

const OnBoarding = () => {
  const [boardingSwiper, setBoardingSwiper] = useState<SwiperCore>();

  return (
    <div>
      <Navigation />
      <Swiper
        style={{ width: "100%", height: "100%" }}
        pagination={true}
        modules={[Pagination]}
        spaceBetween={2}
        slidesPerView={1}
        onInit={(ev: SwiperCore) => {
          setBoardingSwiper(ev);
        }}
      >
        <SwiperSlide>
          <div className="w-full h-full flex flex-col justify-center mt-10 pb-8 lg:pb-0 sm:mt-12 md:mt-12 lg:mt-4">
            <IllustrationBox ilustrasi="illustration1" />

            <div className="text-on-boarding text-center mb-12 lg:mb-12 mt-12 lg:mt-4 flex flex-col justify-center items-center">
              <OnBoardingText
                title="Jelajahi Hadits Bersama Havl"
                description="Aplikasi yang akan membantumu lebih dekat dengan hadits, sebagai pemandu kehidupan."
              />
              <OnBoardingButtons
                slideIndex={1}
                prev={() => boardingSwiper?.slidePrev()}
                next={() => boardingSwiper?.slideNext()}
              />
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="w-full h-full flex flex-col justify-center mt-10 pb-8 lg:pb-0 sm:mt-12 md:mt-12 lg:mt-2">
            <IllustrationBox ilustrasi="illustration2" />

            <div className="text-on-boarding text-center mb-12 lg:mb-12 mt-12 lg:mt-4 flex flex-col justify-center items-center">
              <OnBoardingText
                title="Eksplorasi Hadits"
                description="Kamu dapat mengeksplorasi berbagai macam hadits sesuai dengan topik yang pilih."
              />
              <OnBoardingButtons
                slideIndex={2}
                prev={() => boardingSwiper?.slidePrev()}
                next={() => boardingSwiper?.slideNext()}
              />
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="w-full h-full flex flex-col justify-center mt-10 pb-8 lg:pb-0 sm:mt-12 md:mt-12 lg:mt-2">
            <IllustrationBox ilustrasi="illustration3" />

            <div className="text-on-boarding text-center mb-12 lg:mb-12 mt-12 lg:mt-4 flex flex-col justify-center items-center">
              <OnBoardingText
                title="Temukan Kedamaian"
                description="Menghadirkan kumpulan kutipan bijak dari hadits untuk memberikan kedamaian."
              />
              <OnBoardingButtons
                slideIndex={3}
                prev={() => boardingSwiper?.slidePrev()}
                next={() => boardingSwiper?.slideNext()}
              />
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default OnBoarding;
