import { useNavigate } from "react-router-dom";
import Button from "../../../../components/ui/Button";

interface OnBoardingButtonsProps {
  slideIndex: number;
  prev: () => void;
  next: () => void;
}

const OnBoardingButtons = ({
  slideIndex,
  prev,
  next,
}: OnBoardingButtonsProps) => {
  const navigate = useNavigate();
  const goHome = () => navigate("/");

  return (
    <div className="lg:mt-[-2rem]">
      <Button
        onClick={slideIndex === 3 ? goHome : next}
        label={`${
          slideIndex === 3 ? "Pilih Peranmu Sekarang!" : "Selanjutnya"
        }`}
        className="text-white bg-[#1AA5F2] hover:bg-[#199AE1] text-sm flex items-center space-x-1.5 rounded-3xl font-bold px-10 py-2 mx-auto mt-6 lg:mt-12 my-6 border-2 drop-shadow-primary-blue border-[#1AA5F2]"
      />
      <Button
        onClick={prev}
        label="Kembali"
        className={`${
          slideIndex === 1 ? "opacity-0" : "opacity-100"
        } text-gray-400 font-semibold`}
      />
    </div>
  );
};

export default OnBoardingButtons;
