interface PageTitleProps {
  title: string;
  classPosition: string;
}

const PageTitle = ({ title, classPosition }: PageTitleProps) => {
  return (
    <div className={`flex ${classPosition} items-center mb-5 mt-6`}>
      <h1 className="font-bold text-xl sm:text-2xl mr-4 text-primary-black leading-9 whitespace-pre-wrap sm:whitespace-normal">
        {title}
      </h1>
    </div>
  );
};

export default PageTitle;
