import Navigation from "./components/navigation/Navigation";
import TopicTitle from "./components/topic/TopicTitle";
import ListCardHadits from "./components/topic/Card Hadits/ListCardHadits";
import SaveButton from "./components/save button/SaveButton";
import LineSeparator from "../../components/ui/LineSeparator";
import ListRelatedTopics from "./components/related topics/ListRelatedTopics";
import IllustrationBox from "./components/illustration/IllustrationBox";
import Skeleton from "react-loading-skeleton";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { Topic } from "../../variable interface/Topic";
import { savedTopicsDB } from "../../helper/topicsIndexDB";

// define indexDB table
savedTopicsDB();

const DetailTopic = () => {
  const { id } = useParams();
  const [topicData, setTopicData] = useState<Topic>();

  useEffect(() => {
    setTopicData({
      id: "",
      judul: "",
      hadits: [],
      ilustrasi: "",
      kategori: [],
    });
    const fetchTopicsById = async () => {
      const docRef = doc(db, "topics", id as string);
      const docSnap = await getDoc(docRef);
      const data = docSnap.data() as Topic;
      setTopicData({ ...data, id: id as string });
    };

    fetchTopicsById();
  }, [id]);

  return (
    <div>
      <Navigation />
      <div className="content m-[0.6rem] sm:m-8 mt-[6.5rem] sm:mt-28 mb-12 px-1 sm:px-6">
        <IllustrationBox ilustrasi={topicData?.ilustrasi} />
        <TopicTitle topic={topicData} />
        {topicData && <ListCardHadits listHadits={topicData.hadits} />}
      </div>
      {topicData && <SaveButton topic={topicData} />}
      <LineSeparator marginYClass="my-12" />
      <div className="mb-20 bg-slate-100 bg-opacity-50 py-8">
        {topicData ? (
          <ListRelatedTopics detailTopicId={topicData.id} />
        ) : (
          <Skeleton
            borderRadius={24}
            height={400}
            count={4}
            width={"23rem"}
            className="m-4"
            containerClassName="flex flex-wrap justify-center"
          />
        )}
      </div>
    </div>
  );
};

export default DetailTopic;
