import { useEffect, useState } from "react";
import { Topic, Hadits } from "../../variable interface/Topic";
import { db } from "../../firebase";
import { ReactComponent as RandomIcon } from "../../assets/RandomIcon.svg";
import Navigation from "./components/navigation/Navigation";
import ListFilter from "./components/filter/ListFilter";
import ListTopics from "../../components/list topics/ListTopics";
import Button from "../../components/ui/Button";
import {
  collection,
  getCountFromServer,
  getDocs,
  limit,
  query,
  where,
} from "firebase/firestore";
import { savedTopicsDB } from "../../helper/topicsIndexDB";

// define indexDB table
savedTopicsDB();

const ExploreTopics = () => {
  const [topics, setTopics] = useState<Topic[]>([]);
  const [listCategoryFilter, setListCategoryFilter] = useState<string[]>([]);
  //const [searchBarValue, setSearchBarValue] = useState<string[]>([]);

  const getCategoryFilter = (category: string) => {
    // add category
    if (!listCategoryFilter.includes(category))
      // just add one category
      setListCategoryFilter([category]);
    // delete category
    else {
      setListCategoryFilter(
        listCategoryFilter.filter(
          (currentCategory) => currentCategory !== category
        )
      );
    }
  };

  const fetchTopics = async () => {
    // get max document index
    const coll = collection(db, "topics");
    const q = query(coll);
    let documentMaxIndex: any = await getCountFromServer(q);
    documentMaxIndex = documentMaxIndex.data().count;

    // list of index document that will be used to query 4 random documents based on 4 random index
    let documentArrayOfIndex: number[] = [];

    // list randomNumber = 8 with filter (to increase the chance of filtered documents), and list randomNumber = 4 without filter
    let maxRandomIndex = listCategoryFilter.length > 0 ? 16 : 4;

    while (documentArrayOfIndex.length < maxRandomIndex) {
      let randomIndex: number =
        Math.floor(Math.random() * documentMaxIndex) + 1;
      if (!documentArrayOfIndex.includes(randomIndex))
        documentArrayOfIndex.push(randomIndex);
    }

    let querySnapshot;
    // if filter exist
    if (listCategoryFilter.length > 0)
      querySnapshot = await getDocs(
        query(
          coll,
          where("nomorTopik", "in", documentArrayOfIndex),
          where("kategori", "array-contains-any", listCategoryFilter),
          limit(4)
        )
      );
    // if filter does not exist
    else
      querySnapshot = await getDocs(
        query(coll, where("nomorTopik", "in", documentArrayOfIndex))
      );

    const data: Topic[] = querySnapshot.docs.map((docSnapshot) => {
      return {
        id: docSnapshot.id,
        judul: docSnapshot.data().judul,
        hadits: docSnapshot.data().hadits as Hadits[],
        ilustrasi: docSnapshot.data().ilustrasi,
        kategori: docSnapshot.data().kategori,
      };
    });

    // kalau ga dapet data, re-fetch sampe dapet
    if (data.length === 0) fetchTopics();
    setTopics(data);
    setTimeout(() => {
      window.scrollTo(-1, 0);
    }, 100);
  };

  const randomTopic = () => {
    setTopics([]);
    setTimeout(() => {
      fetchTopics();
    }, 500);
  };

  useEffect(() => {
    fetchTopics();
  }, [listCategoryFilter]);

  return (
    <div className="overflow-x-hidden">
      <Navigation />
      <div className="content m-[1rem] sm:m-8 mt-[6rem] sm:mt-[6.5rem]">
        <ListFilter
          currenListCategory={listCategoryFilter}
          sendListFilterLabel={getCategoryFilter}
        />
        <ListTopics topics={topics} />
        <Button
          className="text-sm bg-[#1AA5F2] flex items-center space-x-1.5 text-white rounded-3xl font-bold px-6 py-1 mx-auto mt-12 mb-20 md:mb-14 hover:bg-[#199AE1]"
          icon={<RandomIcon className="w-[28px]" />}
          label="Acak Topik"
          onClick={randomTopic}
        />
      </div>
    </div>
  );
};

export default ExploreTopics;
