import { Fragment } from "react/jsx-runtime";
import HomeFloatButton from "../../../../components/ui/HomeFloatButton";
import SavedFloatButton from "../saved quotes/SavedFloatButton";

interface TopNavigationProps {
  handleOpenSavedQuotes: () => void;
}

const TopNavigation = ({ handleOpenSavedQuotes }: TopNavigationProps) => {
  return (
    <Fragment>
      <HomeFloatButton />
      <SavedFloatButton handleOpenSavedQuotes={handleOpenSavedQuotes} />
    </Fragment>
  );
};

export default TopNavigation;
