import TopicCard from "./topic/TopicCard";
import { Topic } from "../../variable interface/Topic";
import Skeleton from "react-loading-skeleton";

interface ListTopicsProps {
  topics: Topic[];
}

const ListTopics = ({ topics }: ListTopicsProps) => {
  return (
    <div className="list-topics px-0 sm:px-6 flex flex-wrap justify-center">
      {topics.length > 0 ? (
        topics.map((topic) => <TopicCard key={topic.id} topic={topic} />)
      ) : (
        <Skeleton
          borderRadius={24}
          height={400}
          count={4}
          width={"23rem"}
          className="m-4"
          containerClassName="flex flex-wrap justify-center"
        />
      )}
    </div>
  );
};

export default ListTopics;
