import { useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";

interface ScrollWrapperProps {
  children: React.JSX.Element;
}
// Scroll top when change the navigation
const ScrollWrapper = ({ children }: ScrollWrapperProps) => {
  const location = useLocation();

  useLayoutEffect(() => {
    // give setTimeout because window.scrollTo(0, 0) is trigerred first before the component finish rendering
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }, [location.pathname]);

  return children;
};

export default ScrollWrapper;
