interface FieldsProps {
  name: string;
}

const Fields = ({ name }: FieldsProps) => {
  return (
    <div className="w-1/2 sm:w-[18rem] flex justify-center items-center my-2">
      <span className="text-[#32BFD8] font-semibold px-6 sm:px-10 py-3 border-[1px] border-[#32BFD8] rounded-3xl">
        {name}
      </span>
    </div>
  );
};

export default Fields;
