import { Link } from "react-router-dom";
import ListTopics from "../../../../components/list topics/ListTopics";
import Button from "../../../../components/ui/Button";
import { savedTopicsDB } from "../../../../helper/topicsIndexDB";
import { useEffect, useState } from "react";
import { Topic } from "../../../../variable interface/Topic";
import EmptyState from "../../../../components/ui/EmptyState";

// create dexie db
const db = savedTopicsDB();

interface ListSavedTopicsProps {
  searchString: string;
}

const ListSavedTopics = ({ searchString }: ListSavedTopicsProps) => {
  const [savedTopics, setSavedTopics] = useState<Topic[]>([]);
  const [filteredSavedTopics, setFilteredSavedTopics] = useState<Topic[]>([]);

  useEffect(() => {
    // load data from indexDB
    const loadTopicsFromIndexDB = async () => {
      try {
        const data: Topic[] = await db.table("savedTopicsDB").toArray();
        setSavedTopics(data);
      } catch (error) {
        console.log(error);
      }
    };
    loadTopicsFromIndexDB();
  }, []);

  // handle search filter
  useEffect(() => {
    const flteredSavedTopics = savedTopics.filter((topic) =>
      topic.judul.toLowerCase().includes(searchString.toLowerCase())
    );

    setFilteredSavedTopics(flteredSavedTopics);
  }, [savedTopics, searchString]);

  return (
    <div className="mt-[-0.5rem] sm:mt-0 mx-3 sm:mx-0">
      {filteredSavedTopics.length > 0 ? (
        <ListTopics topics={filteredSavedTopics} />
      ) : (
        <EmptyState
          illustration="emptyState1"
          text={`${
            savedTopics.length > 0
              ? "Topik Tidak Ditemukan.."
              : "Belum Ada Topik yang Disimpan.."
          }`}
        />
      )}

      <Link to="/penjelajah">
        <Button
          className="border-2 border-[#1AA5F2] text-sm bg-white flex items-center space-x-1.5 text-[#1AA5F2] rounded-3xl font-bold px-12 py-2 mx-auto mt-16 mb-8 hover:bg-slate-100"
          label="Lebih banyak topik"
        />
      </Link>
    </div>
  );
};

export default ListSavedTopics;
