import CardHadits from "./CardHadits";
import { Hadits } from "../../../../../variable interface/Topic";
import Skeleton from "react-loading-skeleton";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

interface ListCardHaditsProps {
  listHadits: Hadits[];
}

const ListCardHadits = ({ listHadits }: ListCardHaditsProps) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!listHadits) return navigate("/not-found");
  });

  return (
    <div
      id="list-hadits"
      className="flex flex-col items-center justify-center mt-[-1.5rem]"
    >
      {listHadits ? (
        listHadits?.map((hadits, index) => (
          <CardHadits
            key={index}
            listsumberHadits={hadits.sumberHadits}
            judulHadits={hadits.judulHadits}
            isiHadits={hadits.isiHadits}
          />
        ))
      ) : (
        <Skeleton
          borderRadius={24}
          height={178}
          width={"50vw"}
          count={2}
          className="mt-6 text-center"
        />
      )}
    </div>
  );
};

export default ListCardHadits;
