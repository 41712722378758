import Skeleton from "react-loading-skeleton";
import { LazyLoadImage } from "react-lazy-load-image-component";

interface IllustrationBoxProps {
  ilustrasi: string | undefined;
}

const IllustrationBox = ({ ilustrasi }: IllustrationBoxProps) => {
  return (
    <div className="mb-3 mt-28 bg-opacity-10 rounded-3xl w-11/12 sm:w-8/12 lg:w-7/12 mx-auto ">
      {ilustrasi ? (
        <LazyLoadImage
          alt="ilustrasi"
          className="w-72 h-52 m-auto object-contain"
          src={require(`../../../../assets/illustrations/${ilustrasi}.png`)}
          effect="opacity"
        />
      ) : (
        <Skeleton borderRadius={24} height={178} />
      )}
      <div className="bg-[#41C9E2] bg-opacity-10 w-full h-40 mt-[-10rem] rounded-2xl"></div>
    </div>
  );
};

export default IllustrationBox;
