import { useState } from "react";
import LineSeparator from "../../components/ui/LineSeparator";
import PageTitle from "../../components/ui/PageTitle";
import Navigation from "./components/Navigation/Navigation";
import ListSavedTopics from "./components/Saved Topics/ListSavedTopics";

const SavedTopics = () => {
  const [searchString, setSearchString] = useState<string>("");

  const searchHandle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchString(event.target.value);
  };

  return (
    <div>
      <Navigation searchString={searchString} searchHandle={searchHandle} />
      <div className="mt-[6.5rem] pb-12">
        <div className="mt-32">
          <PageTitle
            classPosition="justify-center m-6"
            title="Topik yang telah disimpan"
          />
          <LineSeparator marginYClass="mb-0 mt-8" />
        </div>
        <ListSavedTopics searchString={searchString} />
      </div>
    </div>
  );
};

export default SavedTopics;
