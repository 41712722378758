import PageTitle from "./components/page title/PageTitle";
import Navigation from "./components/navigation/Navigation";
import ListRoleCard from "./components/choose role/ListRoleCard";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate();

  // first time visit?
  useEffect(() => {
    if (!localStorage.getItem("previouslyVisited")) {
      navigate("/on-boarding");
      localStorage.setItem("previouslyVisited", "true");
    }
  });

  return (
    <div className="overflow-x-hidden">
      <Navigation />
      <div className="mt-[7.5rem] mb-12">
        <PageTitle
          title={"Halo, \nSilahkan Pilih Peranmu"}
          classPosition="justify-start sm:justify-center m-6"
        />
        <div className="mt-[-2rem]">
          <ListRoleCard />
        </div>
      </div>
    </div>
  );
};

export default Home;
