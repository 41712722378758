import { useEffect, useState } from "react";

interface FilterProps {
  isChoosen: boolean;
  filter: string;
  sendFilter: (category: string) => void;
}

const FilterLabel = ({ filter, sendFilter, isChoosen }: FilterProps) => {
  const name = filter;
  const [isChecked, setIsChecked] = useState<boolean>(isChoosen);

  // send filter label to parent
  const toggleFilterLabel = () => {
    sendFilter(name);
    setIsChecked(!isChecked);
  };

  useEffect(() => {
    setIsChecked(isChoosen);
  }, [isChoosen]);

  return (
    <div className="mr-2 sm:mr-4 mt-4 md:mt-4 lg:mt-3" key={name}>
      <input
        type="checkbox"
        id={name}
        name={name}
        checked={isChecked}
        className="hidden peer"
        onChange={toggleFilterLabel}
      />
      <label
        htmlFor={name}
        className="inline-flex items-center w-auto px-6 py-2.5 rounded-3xl cursor-pointer text-[#41C9E2] bg-[#41C9E2]/[0.08] peer-checked:bg-[#35CAE5] peer-checked:text-white hover:bg-[#41C9E2]/[0.24]"
      >
        <div className="text-[14px] w-full font-bold tracking-[0.75px]">
          {name}
        </div>
      </label>
    </div>
  );
};

export default FilterLabel;
