import Button from "../../../../components/ui/Button";
import IllustrationBox from "./IllustrationBox";
import RoleDescription from "./RoleDescription";
import RoleTitle from "./RoleTitle";
import { Fragment } from "react/jsx-runtime";
import TransitionPage from "../transition/TransitionPage";
import { useState } from "react";

interface RoleCardProps {
  title: string;
  description: string;
  pathLink: string;
}

const RoleCard = ({ title, description, pathLink }: RoleCardProps) => {
  const [isTransition, setIsTransition] = useState<boolean>(false);

  const toggleTransition = () => setIsTransition(!isTransition);

  return (
    <Fragment>
      <div className="topics px-4 sm:px-6 pt-6 pb-8 w-12/12 sm:w-[32rem] rounded-3xl bg-white drop-shadow-secondary-blue sm:mr-8 mt-6">
        {/* illustration */}
        <IllustrationBox
          ilustrasi={title === "Penjelajah" ? "illustration6" : "illustration7"}
        />
        <div className="mb-2 sm:mb-1.5 space-y-3">
          {/* judul */}
          <RoleTitle title={title} />
          {/* isi */}
          <RoleDescription description={description} />
          <Button
            onClick={toggleTransition}
            label="Mulai"
            className="text-[15px] px-8 py-1.5 bg-[#41C9E2] text-white rounded-3xl font-semibold hover:bg-[#40BDD5]"
          />
        </div>
      </div>
      <TransitionPage
        isShow={isTransition}
        roleTitle={title}
        pathLink={pathLink}
      />
    </Fragment>
  );
};

export default RoleCard;
