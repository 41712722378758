import { useEffect, useState } from "react";
import NavLogo from "../../../../components/navigation/NavLogo";
import SearchBar from "../../../../components/navigation/SearchBar";
import SearchButton from "../../../../components/navigation/SearchButton";
import BackLink from "../../../../components/navigation/BackLink";
import AboutUsLink from "../../../../components/navigation/AboutUsLink";

interface NavigationProps {
  searchString: string;
  searchHandle: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const Navigation = ({ searchHandle, searchString }: NavigationProps) => {
  const [show, setShow] = useState<boolean>(true);
  const [showSearchMobile, setShowSearchMobile] = useState<boolean>(false);
  const [lastScrollY, setLastScrollY] = useState<number>(0);

  const toggleSearchBar = () => setShowSearchMobile(!showSearchMobile);

  useEffect(() => {
    const controlNavbar = () => {
      if (window.scrollY > lastScrollY && window.scrollY > 0) {
        // if scroll down hide the navbar and search mobile
        setShow(false);
        setShowSearchMobile(false);
      } else {
        // if scroll up show the navbar
        setShow(true);
      }

      // show nav and searh fields when the search field is filled
      if (searchString.length > 0) {
        setShow(true);
        setShowSearchMobile(true);
      }
      // remember current page location to use in the next move
      setLastScrollY(window.scrollY);
    };

    window.addEventListener("scroll", controlNavbar);

    // cleanup function
    return () => {
      window.removeEventListener("scroll", controlNavbar);
    };
  }, [lastScrollY]);

  return (
    <nav
      className={`${show ? "translate-y-[0%]" : "translate-y-[-100%]"} ${
        showSearchMobile ? "h-[124px]" : "h-[4.25rem]"
      } sm:h-16 duration-300 fixed top-0 left-1/2 translate-x-[-50%] z-10 bg-[#1AA5F2] drop-shadow-primary-blue mx-auto my-4 px-7 pt-3 py-2.5 rounded-3xl flex flex-col justify-between items-start w-11/12 md:w-10/12`}
    >
      <div className="flex justify-between items-center w-full mt-[-1px]">
        <div className="flex items-center space-x-2.5">
          <BackLink />
          <span className="block pl-0 pr-0.5 sm:hidden">
            <SearchButton
              onClick={toggleSearchBar}
              isSearchActive={showSearchMobile}
            />
          </span>
          <AboutUsLink />
        </div>
        <div className="nav-links flex items-center">
          <NavLogo />
          {/* search bar medium-large */}
          <span className="hidden sm:block">
            <SearchBar searchHandle={searchHandle} />
          </span>
        </div>
      </div>
      {/* search bar mobile */}
      <span
        className={`${
          showSearchMobile ? "block" : "hidden"
        } block sm:hidden mt-4 mb-3 flex items-center`}
      >
        <SearchBar searchHandle={searchHandle} />
      </span>
    </nav>
  );
};

export default Navigation;
