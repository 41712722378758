import { useState, useEffect } from "react";
import { ReactComponent as LogoWhite } from "../../../../assets/LogoWhite.svg";

const Navigation = () => {
  const [show, setShow] = useState<boolean>(true);
  const [lastScrollY, setLastScrollY] = useState<number>(0);

  useEffect(() => {
    const controlNavbar = () => {
      if (window.scrollY > lastScrollY && window.scrollY > 0) {
        // if scroll down hide the navbar and search mobile
        setShow(false);
      } else {
        // if scroll up show the navbar
        setShow(true);
      }
      // remember current page location to use in the next move
      setLastScrollY(window.scrollY);
    };

    window.addEventListener("scroll", controlNavbar);

    // cleanup function
    return () => {
      window.removeEventListener("scroll", controlNavbar);
    };
  }, [lastScrollY]);

  return (
    <nav
      className={`${
        show ? "translate-y-[0%]" : "translate-y-[-100%]"
      } h-[4rem] top-0 duration-300 fixed left-1/2 translate-x-[-50%] z-10 bg-[#1AA5F2] drop-shadow-primary-blue mx-auto my-4 px-7 pt-3 py-2.5 rounded-3xl flex justify-center items-center w-6/12 md:w-3/12`}
    >
      <LogoWhite className="w-[5.2rem]" />
    </nav>
  );
};

export default Navigation;
