import { LazyLoadImage } from "react-lazy-load-image-component";

interface EmptyStateProps {
  text: string;
  illustration: string;
}

const EmptyState = ({ text, illustration }: EmptyStateProps) => {
  return (
    <div className="mb-3 mt-6 bg-opacity-10 rounded-3xl w-11/12 sm:w-8/12 lg:w-7/12 mx-auto">
      <LazyLoadImage
        alt="ilustrasi"
        className="w-96 h-64 m-auto object-contain"
        src={require(`../../assets/illustrations/${illustration}.png`)}
        effect="opacity"
      />
      <div className="bg-[#41C9E2] bg-opacity-10 w-full h-40 mt-[-10rem] rounded-2xl"></div>
      <h2 className="font-bold text-xl text-secondary-black text-center mt-6">
        {text}
      </h2>
    </div>
  );
};

export default EmptyState;
