const TentangHavl = () => {
  return (
    <div className="flex flex-col justify-center items-center text-center text-secondary-black">
      <h3 className="mb-3">Tentang Havl</h3>
      <h2 className="text-2xl font-bold text-primary-black w-full sm:w-[40rem] leading-10">
        Kami Ingin Berperan Dalam Mendorong Masyakarat Indonesia Menjadi Manusia
        yang Terarah Melalui <span className="text-[#32BFD8]">Hadits.</span>
      </h2>
    </div>
  );
};

export default TentangHavl;
