import { ReactComponent as SearchIcon } from "../../assets/navigation icon/SearchWhite.svg";

interface SearchButtonProps {
  onClick: () => void;
  isSearchActive: boolean;
}

const SearchButton = ({ onClick, isSearchActive }: SearchButtonProps) => {
  return (
    <button
      className={`${
        isSearchActive ? "bg-[#41C9E2]" : "bg-none"
      }  rounded-full px-3 py-[0.6rem]`}
      onClick={onClick}
    >
      <SearchIcon className="w-7" />
    </button>
  );
};

export default SearchButton;
