interface OnBoardingTextProps {
  title: string;
  description: string;
}

const OnBoardingText = ({ title, description }: OnBoardingTextProps) => {
  return (
    <>
      <h2 className="font-bold text-lg md:text-xl text-primary-black">
        {title}
      </h2>
      <p className="mt-2 text-base md:text-lg text-secondary-black leading-9 md:leading-9 w-11/12 sm:w-7/12 lg:w-5/12">
        {description}
      </p>
    </>
  );
};

export default OnBoardingText;
