interface SaveIconProps {
  topicId: number;
  isSaved: boolean;
}

const SaveIcon = ({ topicId, isSaved }: SaveIconProps) => {
  return (
    <span className="cursor-pointer">
      {/* love icon */}
      <svg
        className="mx-auto mb-[-3px]"
        width="11"
        height="11"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className={`${isSaved ? "fill-[#1AA5F2]" : "fill-none"}`}
          d="M1.38608 2.38608C0.20464 3.56752 0.204641 5.48301 1.38608 6.66445L5.9685 11.2469L6 11.2154L6.03153 11.2469L10.614 6.66448C11.7954 5.48304 11.7954 3.56755 10.614 2.38612C9.43252 1.20468 7.51702 1.20468 6.33559 2.38612L6.17681 2.54489C6.07918 2.64252 5.92089 2.64252 5.82326 2.54489L5.66444 2.38608C4.48301 1.20464 2.56752 1.20464 1.38608 2.38608Z"
        />
      </svg>

      {/* save icon */}
      <svg
        className="w-[22px]"
        width="28"
        height="34"
        viewBox="0 0 28 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className={`stroke-[3px] stroke-[#1AA5F2] ${
            isSaved ? "fill-[#1AA5F2]" : "fill-none "
          }`}
          d="M4.00001 0.333328C3.7698 0.333328 3.54647 0.357303 3.32943 0.401688C1.81015 0.712381 0.666672 2.0552 0.666672 3.66666V33.6667L14 28.6667L27.3333 33.6667V3.66666C27.3333 3.43749 27.3096 3.21256 27.265 2.99609C26.9987 1.69384 25.9728 0.667996 24.6706 0.401688C24.4535 0.357303 24.2302 0.333328 24 0.333328H4.00001Z"
        />
      </svg>
    </span>
  );
};

export default SaveIcon;
