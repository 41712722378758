import AOS from "aos";
import "aos/dist/aos.css";
import Navigation from "./components/navigation/Navigation";
import Button from "../../components/ui/Button";
import { ReactComponent as MessageIcon } from "../../assets/messageIcon.svg";
import TentangHavl from "./components/tentang havl/TentangHavl";
import ListCardMission from "./components/card mission/ListCardMission";
import TentangHadits from "./components/tentang hadits/TentangHadits";
import ListFields from "./components/fields/ListFields";
import ListSdgsLogo from "./components/target sdgs/ListSdgsLogo";
import ProjectProgress from "./components/project progress/ProjectProgress";
import ListCardSupports from "./components/supports/ListCardSupports";
import { useEffect } from "react";

const AboutUs = () => {
  useEffect(() => {
    AOS.init();
  });

  return (
    <div className="overflow-x-hidden">
      <Navigation />
      <div className="content m-[1rem] sm:m-8 mt-[8rem] sm:mt-[8rem]">
        {/* tentang havl */}
        <div
          data-aos="fade-up"
          data-aos-offset={200}
          data-aos-delay={200}
          data-aos-duration={600}
        >
          <TentangHavl />
        </div>

        {/* end of tentang havl */}

        {/* list card misi */}
        <div
          data-aos="fade-up"
          data-aos-offset={200}
          data-aos-delay={200}
          data-aos-duration={600}
        >
          <ListCardMission />
        </div>

        {/* end of list card misi */}

        {/* about hadits */}
        <div
          data-aos="fade-up"
          data-aos-offset={200}
          data-aos-delay={200}
          data-aos-duration={600}
        >
          <TentangHadits />
        </div>

        {/* end of about hadits */}

        {/* list bidang */}
        <div
          data-aos="fade-up"
          data-aos-offset={200}
          data-aos-delay={200}
          data-aos-duration={600}
        >
          <ListFields />
        </div>
        {/* end of list bidang */}

        {/* target SDGs */}
        <div
          data-aos="fade-up"
          data-aos-offset={200}
          data-aos-delay={200}
          data-aos-duration={600}
        >
          <ListSdgsLogo />
        </div>
        {/* end of target SDGs */}

        {/* current progress */}
        <div
          data-aos="fade-up"
          data-aos-offset={200}
          data-aos-delay={200}
          data-aos-duration={600}
        >
          <ProjectProgress />
        </div>

        {/* end of current progress */}

        {/* list card support */}
        <div
          data-aos="fade-up"
          data-aos-offset={200}
          data-aos-delay={200}
          data-aos-duration={600}
        >
          <ListCardSupports />
        </div>

        {/* end of list support card */}

        {/* feedback button */}

        <Button
          onClick={() =>
            window.open(
              "https://z0nul0yfva8.typeform.com/to/vIqCeEVT",
              "_blank"
            )
          }
          className="text-sm bg-white flex items-center space-x-2 text-[#1AA5F2] rounded-3xl font-bold px-9 py-1.5 mx-auto mt-16 mb-20 md:mb-14 hover:bg-gray-50 border-2 border-[#1AA5F2]"
          icon={<MessageIcon className="w-[28px]" />}
          label="Sampaikan Pesan"
        />
      </div>
    </div>
  );
};

export default AboutUs;
