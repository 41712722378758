interface RoleDescriptionProps {
  description: string;
}

const RoleDescription = ({ description }: RoleDescriptionProps) => {
  return (
    <div>
      <p className="text-[#425466] text-[16px] leading-8 line-clamp-3 mt-[-0.42rem]">
        {description}
      </p>
    </div>
  );
};

export default RoleDescription;
