import { Topic } from "../../../variable interface/Topic";
import SaveIcon from "./Saveicon";
import ListHadits from "./ListHadits";
import PeakHadits from "./PeakHadits";
import TopicTitle from "./TopicTitle";
import { Link } from "react-router-dom";
import IllustrationBox from "./IllustrationBox";
import { useState } from "react";

interface TopicCardProps {
  topic: Topic;
}

const TopicCard = ({ topic }: TopicCardProps) => {
  const { id, judul, hadits, ilustrasi }: Topic = topic;
  const [titleRef, setTitleRef] = useState<HTMLElement | null>(null);

  return (
    <div className="topics px-4 sm:px-6 pt-5 pb-4 w-full sm:w-[32rem] lg:w-[25.6rem] xl:w-[32rem] rounded-3xl bg-white drop-shadow-secondary-blue sm:mr-8 mt-6">
      {/* illustration */}
      <IllustrationBox ilustrasi={ilustrasi} topikId={id} />
      {/* judul */}
      <div className="flex justify-between items-center">
        <Link to={`/penjelajah/${id}`}>
          <TopicTitle sendTitleRef={setTitleRef} title={judul} />
        </Link>
        {/* save button */}
        <SaveIcon topic={topic} />
      </div>
      <Link to={`/penjelajah/${id}`}>
        {/* isi */}
        <PeakHadits titleRef={titleRef} isiHadits={hadits[0].isiHadits} />
        {/* list hadist */}
        <ListHadits listHadits={hadits[0].sumberHadits} />
      </Link>
    </div>
  );
};

export default TopicCard;
