interface ListHaditsProps {
  listsumberHadits: string[];
}

const ListHadits = ({ listsumberHadits }: ListHaditsProps) => {
  return (
    <div className="flex flex-wrap mb-4 mt-1.5 md:mt-1">
      {listsumberHadits.map((sumberHadits: string, index) => (
        <div
          key={sumberHadits}
          className={`${
            index % 2 === 0
              ? "bg-[#35CAE5]/[0.075] text-[#32BFD8]"
              : "bg-[#38DCD8]/[0.075] text-[#2fc7c3]"
          } mr-2 mt-2 text-[15px] font-semibold rounded-3xl px-[0.85rem] py-[0.3rem]`}
        >
          {sumberHadits}
        </div>
      ))}
    </div>
  );
};

export default ListHadits;
