import CardSupport from "./CardSupport";
import Button from "../../../../components/ui/Button";
import { ReactComponent as InstagramIcon } from "../../../../assets/instagramIcon.svg";
import { ReactComponent as DonateIcon } from "../../../../assets/donateIcon.svg";

const ListCardSupports = () => {
  return (
    <>
      <div className="flex flex-col justify-center items-center text-center text-secondary-black">
        <h2 className="text-2xl font-bold text-primary-black w-[40rem] leading-10">
          Kami Butuh Dukunganmu!
        </h2>
        <h3 className="mt-3 text-lg w-11/12 sm:w-[32rem] text-secondary-black">
          Jadilah bagian dari perjalanan ini, dan kita akan memberikan dampak
          positif yang lebih besar!
        </h3>
      </div>
      {/* end of supports title */}

      {/* list support card */}

      <div className="flex flex-wrap items-center justify-center mt-8">
        {/* card support  */}
        <CardSupport
          illustration="illustration29"
          title="Beri Kami Donasi Terbaikmu"
          button={
            <Button
              onClick={() => window.open("https://saweria.co/havl", "_blank")}
              className="mx-auto mt-3 text-[13px] bg-[#1AA5F2] flex items-center space-x-1.5 text-white rounded-3xl font-semibold px-6 hover:bg-[#199AE1] h-[2.4rem]"
              icon={<DonateIcon className="w-[21px]" />}
              label="Kirim Donasi"
            />
          }
        />
        {/* end of card support  */}

        {/* card support  */}
        <CardSupport
          illustration="illustration19"
          title="Promosikan Proyek Kami"
          button={
            <Button
              onClick={() =>
                window.open("https://www.instagram.com/havl.app/", "_blank")
              }
              className="mx-auto mt-3 text-[13px] bg-[#1AA5F2] flex items-center space-x-1.5 text-white rounded-3xl font-semibold px-6 hover:bg-[#199AE1] h-[2.4rem]"
              icon={<InstagramIcon className="w-[21px]" />}
              label="@havl.app"
            />
          }
        />
        {/* end of card support */}
      </div>

      <div className="flex flex-wrap items-center justify-center mt-0 sm:mt-4 mb-12">
        {/* card support  */}
        <CardSupport
          illustration="illustration37"
          title="Sebut kami dalam do’a baikmu"
        />
        {/* end of card support  */}
      </div>
    </>
  );
};

export default ListCardSupports;
