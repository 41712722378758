import { useEffect, useState } from "react";
import Button from "../../../../components/ui/Button";
import SaveIcon from "./Saveicon";
import { Topic } from "../../../../variable interface/Topic";
import {
  deleteTopicByIdFromIndexDB,
  loadTopicByIdFromIndexDB,
  saveTopicToIndexDB,
} from "../../../../helper/topicsIndexDB";

interface SaveButtonProps {
  topic: Topic;
}

enum ButtonClass {
  saved = "text-white bg-[#1AA5F2] hover:bg-[#199AE1]",
  notSaved = "text-[#1AA5F2] bg-white hover:bg-[#FBFAFA]",
}

const SaveButton = ({ topic }: SaveButtonProps) => {
  const [saveState, setSaveState] = useState<boolean>(true);
  const [show, setShow] = useState<boolean>(true);
  const [lastScrollY, setLastScrollY] = useState<number>(0);

  const saveDataToIndexDB = async (topic: Topic) => {
    try {
      await saveTopicToIndexDB(topic);
    } catch (error) {
      console.log(error);
    }
  };

  const toggleSave = () => {
    if (saveState) deleteTopicByIdFromIndexDB(topic.id);
    else saveDataToIndexDB(topic);
    setSaveState(!saveState);
  };

  useEffect(() => {
    const checkDataFromIndexDB = async (id: string) => {
      try {
        const data = await loadTopicByIdFromIndexDB(id);
        setSaveState(!!data);
      } catch (error) {
        console.log(error);
      }
    };
    // get data from index DB
    if (topic) checkDataFromIndexDB(topic.id);

    const controlFloatButton = () => {
      const maxScrollY =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight;

      if (maxScrollY - window.scrollY <= 100) {
        // if max scroll Y
        setShow(true);
      } else if (window.scrollY > lastScrollY) {
        // if scroll down hide the navbar
        setShow(false);
      } else {
        // if scroll up show the navbar
        setShow(true);
      }
      // remember current page location to use in the next move
      setLastScrollY(window.scrollY);
    };
    window.addEventListener("scroll", controlFloatButton);
    // cleanup function
    return () => {
      window.removeEventListener("scroll", controlFloatButton);
    };
  }, [lastScrollY, topic]);

  return (
    <div className="mt-8">
      <Button
        className={`${
          show ? "translate-y-[0%]" : "translate-y-[135%]"
        } duration-300 fixed bottom-0 left-1/2 translate-x-[-50%] z-10 text-sm flex items-center space-x-1.5 rounded-3xl font-bold px-6 py-1 mx-auto my-8 border-2 drop-shadow-primary-blue border-[#1AA5F2] ${
          saveState ? ButtonClass.saved : ButtonClass.notSaved
        }`}
        icon={<SaveIcon isSaved={saveState} />}
        onClick={toggleSave}
        label={saveState ? "Topik disimpan" : "Simpan Topik"}
      />
    </div>
  );
};

export default SaveButton;
