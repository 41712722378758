import { ReactComponent as CrossIcon } from "../../../../assets/CrossIcon.svg";
import FilterLabel from "./FilterLabel";

interface ModalFilterProps {
  currenListCategory: string[];
  filterCategories: string[];
  sendCategoryFilter: (category: string) => void;
  handleOpenMoreFilter: () => void;
}

const ModalMoreFilter = ({
  currenListCategory,
  filterCategories,
  sendCategoryFilter,
  handleOpenMoreFilter,
}: ModalFilterProps) => {
  return (
    <div className="overflow-y-auto shadow bg-gray-700/[0.50] overflow-x-hidden fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full md:inset-0 h-full">
      {/* start - modal content */}
      <div className="bg-white font-bold px-10 py-10 rounded-3xl w-screen sm:w-10/12 lg:w-6/12 m-4 sm:m-2">
        <div>
          <div className="flex justify-between items-center">
            <h2 className="text-lg">Lebih banyak topik</h2>
            <button
              onClick={handleOpenMoreFilter}
              className="px-3 py-2.5 cursor-pointer bg-[#1AA5F2]/[0.12] hover:bg-[#1AA5F2]/[0.24] rounded-full"
            >
              <CrossIcon className="w-4" />
            </button>
          </div>

          <div className="list-filter flex flex-wrap pt-2 md:pt-4 lg:pt-4 mb-6">
            {filterCategories.map((filter) => (
              <FilterLabel
                isChoosen={currenListCategory.includes(filter)}
                sendFilter={sendCategoryFilter}
                filter={filter}
              />
            ))}
          </div>
        </div>
      </div>
      {/* end - modal content */}
    </div>
  );
};

export default ModalMoreFilter;
