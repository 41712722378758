import { LazyLoadImage } from "react-lazy-load-image-component";

interface SdgsLogoProps {
  sdgNumber: string;
}

const SdgsLogo = ({ sdgNumber }: SdgsLogoProps) => {
  return (
    <div className="w-3/12 m-3 sm:m-4">
      <LazyLoadImage
        className="w-32 m-auto"
        alt="SDGs"
        src={require(`../../../../assets/sdgs icon/SDGs${sdgNumber}.png`)}
        effect="opacity"
      />
    </div>
  );
};

export default SdgsLogo;
