import { useNavigate } from "react-router-dom";
import { ReactComponent as BackIcon } from "../../assets/BackIcon.svg";

const BackLink = () => {
  const navigate = useNavigate();

  const back = () => navigate(-1);

  return (
    <button className="rounded-full px-1.5 mr-1.5 py-2" onClick={back}>
      <BackIcon className="w-6" />
    </button>
  );
};

export default BackLink;
