import parse from "html-react-parser";

interface PeakHaditsProps {
  titleRef: HTMLElement | null;
  isiHadits: string;
}

const PeakHadits = ({ titleRef, isiHadits }: PeakHaditsProps) => {
  const getNumberOfLines = (element: HTMLElement | null): number => {
    if (element) {
      const lineHeight = parseInt(
        window.getComputedStyle(element).lineHeight || "0"
      );
      const height = parseInt(window.getComputedStyle(element).height || "0");
      return Math.round(height / lineHeight);
    }
    return 0;
  };

  return (
    <div className="">
      <p
        className={`${
          getNumberOfLines(titleRef) === 1
            ? "line-clamp-1 sm:line-clamp-2 h-8 sm:h-16"
            : "line-clamp-1 sm:line-clamp-1 h-8 mt-1.5"
        } text-[#425466] text-[16px] sm:text-[16px] leading-8 md:w-[95%] lg:w-[94%]`}
      >
        "{parse(isiHadits)}"
      </p>
    </div>
  );
};

export default PeakHadits;
