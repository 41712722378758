import { ReactComponent as SearchIcon } from "../../assets/navigation icon/Search.svg";

interface SearchBarProps {
  searchHandle?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const SearchBar = ({ searchHandle }: SearchBarProps) => (
  <div className="search-container bg-white ml-unset sm:ml-4 rounded-3xl flex items-center pl-3">
    <label htmlFor="search-bar">
      <SearchIcon className="w-6 md:w-7" />
    </label>
    <input
      onChange={searchHandle}
      id="search-bar"
      type="text"
      className="bg-white p-2 md:px-2 md:py-2 rounded-3xl outline-none w-full mx-w sm:w-64 md:w-80 m text-[#1AA5F2]"
    />
  </div>
);

export default SearchBar;
