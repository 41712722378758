import Fields from "./Fields";

const ListFields = () => {
  return (
    <>
      <div className="flex justify-center items-center mt-2 sm:mt-12 flex-wrap">
        <Fields name="Sosial" />
        <Fields name="Adab" />
        <Fields name="Lingkungan" />
      </div>

      <div className="flex justify-center items-center mt-2  sm:mt-12  flex-wrap">
        <Fields name="Pendidikan" />
        <Fields name="Profesionalitas" />
      </div>
    </>
  );
};

export default ListFields;
