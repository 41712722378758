import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import {
  initializeAppCheck,
  ReCaptchaEnterpriseProvider,
} from "firebase/app-check";

// Initialize Firebase
const firebaseConfig = JSON.parse(
  process.env.REACT_APP_FIREBASE_CONFIG as string
);
const app = initializeApp(firebaseConfig);

const appcheck = initializeAppCheck(app, {
  provider: new ReCaptchaEnterpriseProvider(
    process.env.REACT_APP_reCAPTCHA_KEY as string
  ),
  isTokenAutoRefreshEnabled: true,
});

export const db = getFirestore(app);
