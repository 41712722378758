interface RoleTitleProps {
  title: string;
}

const RoleTitle = ({ title }: RoleTitleProps) => {
  return (
    <h2 className="font-bold text-[18px] text-primary-black mt-5">{title}</h2>
  );
};

export default RoleTitle;
