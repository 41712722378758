import { Routes, Route } from "react-router-dom";
import Home from "../pages/Home/Home";
import ExploreTopics from "../pages/ExploreTopics/ExploreTopics";
import Quotes from "../pages/Quotes/Quotes";
import DetailTopic from "../pages/Detail Topic/DetailTopic";
import SavedTopics from "../pages/Saved Topics/SavedTopics";
import OnBoarding from "../pages/On Boarding/OnBoarding";
import AboutUs from "../pages/About Us/AboutUs";
import NotFound from "../pages/NotFound/NotFound";
import SiteMap from "../pages/Generate Site Map/SiteMap";

const ListRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />}></Route>
      <Route path="*" element={<NotFound />}></Route>
      {/* <Route path="/generate-sitemap" element={<SiteMap />}></Route> */}
      <Route path="/on-boarding" element={<OnBoarding />}></Route>
      <Route path="/penjelajah" element={<ExploreTopics />}></Route>
      <Route path="/pencari-makna" element={<Quotes />}></Route>
      <Route path="/penjelajah/:id" element={<DetailTopic />}></Route>
      <Route path="/saved-topics" element={<SavedTopics />}></Route>
      <Route path="/about-us" element={<AboutUs />}></Route>
    </Routes>
  );
};

export default ListRoutes;
