import { useEffect, useState } from "react";
import NavLogo from "../../../../components/navigation/NavLogo";
import SavedItemsLink from "../../../../components/navigation/SavedItemsLink";
import AboutUsLink from "../../../../components/navigation/AboutUsLink";
// import SearchBar from "../../../../components/navigation/SearchBar";
// import SearchButton from "../../../../components/navigation/SearchButton";
import HomeLink from "../../../../components/navigation/HomeLink";

const Navigation = () => {
  const [show, setShow] = useState<boolean>(true);
  const [showSearchMobile, setShowSearchMobile] = useState<boolean>(false);
  const [lastScrollY, setLastScrollY] = useState<number>(0);

  //const toggleSearchBar = () => setShowSearchMobile(!showSearchMobile);

  useEffect(() => {
    const controlNavbar = () => {
      if (window.scrollY > lastScrollY && window.scrollY > 0) {
        // if scroll down hide the navbar and search mobile
        setShow(false);
        setShowSearchMobile(false);
      } else {
        // if scroll up show the navbar
        setShow(true);
      }
      // remember current page location to use in the next move
      setLastScrollY(window.scrollY);
    };

    window.addEventListener("scroll", controlNavbar);

    // cleanup function
    return () => {
      window.removeEventListener("scroll", controlNavbar);
    };
  }, [lastScrollY]);

  return (
    <nav
      className={`${show ? "translate-y-[0%]" : "translate-y-[-99%]"} ${
        showSearchMobile ? "h-[128px]" : "h-[4.25rem]"
      } sm:h-16 duration-300 fixed top-0 left-1/2 translate-x-[-50%] z-10 bg-[#1AA5F2] drop-shadow-primary-blue mx-auto my-4 px-7 pt-3 py-2.5 rounded-3xl flex flex-col justify-between items-start w-11/12 md:w-10/12`}
    >
      <div className="flex justify-between items-center w-full mt-[-2px]">
        <NavLogo />
        <div className="nav-links flex items-center">
          <HomeLink />
          <SavedItemsLink />
          <AboutUsLink />
          {/* <span className="block ml-2 sm:hidden">
            <SearchButton
              onClick={toggleSearchBar}
              isSearchActive={showSearchMobile}
            />
          </span> */}
          {/* search bar medium-large */}
          {/* <span className="hidden sm:block">
            <SearchBar />
          </span> */}
        </div>
      </div>
      {/* search bar mobile */}
      {/* <span
        className={`${
          showSearchMobile ? "block" : "hidden"
        } block sm:hidden mt-4 mb-3 flex items-center`}
      >
        <SearchBar />
      </span> */}
    </nav>
  );
};

export default Navigation;
