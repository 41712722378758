import RoleCard from "./RoleCard";

const ListRoleCard = () => {
  return (
    <div className="flex items-center flex-wrap justify-center m-6">
      <RoleCard
        title="Penjelajah"
        description="Mengeksplorasi hadits melalui berbagai topik tertentu yang relevan dengan kehidupan sehari-hari."
        pathLink="/penjelajah"
      />
      <RoleCard
        title="Pencari Makna"
        description="Menemukan kutipan-kutipan bijak dari hadits yang memberikan suasana positif pada kehidupan."
        pathLink="/pencari-makna"
      />
    </div>
  );
};

export default ListRoleCard;
