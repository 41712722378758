import parse from "html-react-parser";

interface IsiHaditsProps {
  isiHadits: string;
  judulHadits: string;
}

const IsiHadits = ({ isiHadits, judulHadits }: IsiHaditsProps) => {
  return (
    <div className="mt-5 pl-0.5 pr-2 sm:pr-0.5">
      {/* tambahin juduul hadits */}
      <h3 className="text-[18px] md:text-[18px] font-bold text-primary-black mb-1 leading-8 md:leading-9">
        {judulHadits}
      </h3>

      <p className="isi-hadits text-[17px] md:text-[17px] text-secondary-black mt-1 ml-[-0.05rem] leading-8 md:leading-9">
        <span className="pr-[0.15rem]">"</span>
        {parse(isiHadits)}"
      </p>
    </div>
  );
};

export default IsiHadits;
