import Dexie from "dexie";
import { QuotesHadits } from "../pages/Quotes/Quotes";

const db = new Dexie("Havl Database");

// Define a table 'savedHaditsQuotes' with 'id, sumberHadits, isiHadits' column
export const savedHaditsQuotesDB = () => {
  db.version(1).stores({
    savedHaditsQuotes: "id, sumberHadits, isiHadits",
  });
  return db;
};

export const loadDataByIdFromIndexDB = async (id: string) => {
  try {
    const data = await db.table("savedHaditsQuotes").get(id);
    return data;
  } catch (error) {
    throw error;
  }
};

export const deleteDataByIdFromIndexDB = async (id: string) => {
  try {
    await db.table("savedHaditsQuotes").delete(id);
  } catch (error) {
    throw error;
  }
};

export const saveDataByIdToIndexDB = async (hadits: QuotesHadits) => {
  try {
    await db.table("savedHaditsQuotes").add(hadits);
  } catch (error) {
    throw error;
  }
};
