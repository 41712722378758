import { Link } from "react-router-dom";
import { ReactComponent as SavedItemsIcon } from "../../assets/navigation icon/ListSavedItems.svg";

const SavedItemsLink = () => (
  <Link to={"/saved-topics"} className="">
    <div className="rounded-full px-5 py-[0.5rem]">
      <SavedItemsIcon className="w-[1.32rem]" />
    </div>
  </Link>
);

export default SavedItemsLink;
