import { Fragment } from "react/jsx-runtime";
import FilterLabel from "./FilterLabel";
import MoreFilterButton from "./MoreFilterButton";
import ModalMoreFilter from "./ModalMoreFilter";
import { useEffect, useState } from "react";
import { shuffleArray } from "../../../../helper/helper";

const filterCategories: string[] = [
  "Sosial",
  "Nasihat",
  "Adab",
  "Pendidikan",
  "Bekerja",
  "Waktu",
];

interface ListFilterProps {
  sendListFilterLabel: (listFilterLabel: string) => void;
  currenListCategory: string[];
}

const ListFilter = ({
  sendListFilterLabel,
  currenListCategory,
}: ListFilterProps) => {
  const [categories, setCategories] = useState<string[]>(filterCategories);
  const [isOpenMoreFilter, setIsOpenMoreFilter] = useState<boolean>(false);

  const handleOpenMoreFilter = () => {
    setIsOpenMoreFilter(!isOpenMoreFilter);
  };

  const getCategoryFilter = (choosenCategory: string) => {
    // add hidden filter (just appears in modal) to show
    if (categories.indexOf(choosenCategory) > 3) {
      let filteredCategories = categories.filter(
        (category) => category !== choosenCategory
      );
      setCategories([choosenCategory, ...filteredCategories]);
    }

    // send filter label to parent
    sendListFilterLabel(choosenCategory);
  };

  // shuffle list filter categories
  useEffect(() => {
    setCategories(shuffleArray(categories));
  }, []);

  return (
    <Fragment>
      {!isOpenMoreFilter && (
        <div className="list-filter flex mt-5 mb-1.5 flex-wrap sm:justify-center">
          {/* start - some list filter */}
          {categories.slice(0, 4).map((filter) => (
            <FilterLabel
              isChoosen={currenListCategory.includes(filter)}
              sendFilter={getCategoryFilter}
              filter={filter}
              key={filter}
            />
          ))}
          {/* end - some list filter */}

          {/* start - more filter button */}
          <MoreFilterButton handleOpenMoreFilter={handleOpenMoreFilter} />
          {/* end - more filter button */}
        </div>
      )}

      {isOpenMoreFilter && (
        <ModalMoreFilter
          currenListCategory={currenListCategory}
          sendCategoryFilter={getCategoryFilter}
          filterCategories={filterCategories}
          handleOpenMoreFilter={handleOpenMoreFilter}
        />
      )}
    </Fragment>
  );
};

export default ListFilter;
