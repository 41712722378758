import IllustrationBox from "./IllustrationBox";

interface CardMissionProps {
  title: string;
  illustration: string;
}

const CardMission = ({ title, illustration }: CardMissionProps) => {
  return (
    <div className="topics px-4 sm:px-6 pt-6 pb-6 w-full sm:w-[20rem] rounded-3xl bg-white drop-shadow-secondary-blue sm:mr-8 mt-6">
      {/* illustration */}
      <IllustrationBox ilustrasi={illustration} />
      <h2 className="text-center text-[17px] text-secondary-black font-semibold pt-2 w-7/12 sm:w-11/12 m-auto">
        {title}
      </h2>
    </div>
  );
};

export default CardMission;
