interface ButtonProps {
  onClick?: () => void;
  label: string;
  className: string;
  icon?: React.ReactNode;
}

const Button = ({ onClick, label, className, icon }: ButtonProps) => (
  <button className={className} onClick={onClick}>
    {icon} <span className="tracking-wide">{label}</span>
  </button>
);

export default Button;
