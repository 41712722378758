import { useEffect, useState } from "react";
import Button from "../../../../../components/ui/Button";
import SaveIcon from "./Saveicon";
import { QuotesHadits } from "../../../Quotes";
import {
  deleteDataByIdFromIndexDB,
  loadDataByIdFromIndexDB,
  saveDataByIdToIndexDB,
} from "../../../../../helper/quotesIndexDB";

interface SaveButtonProps {
  hadits: QuotesHadits;
}

enum ButtonClass {
  saved = "text-white bg-[#1AA5F2] hover:bg-[#199AE1]",
  notSaved = "text-[#1AA5F2] bg-white hover:bg-[#FBFAFA]",
}

const SaveButton = ({ hadits }: SaveButtonProps) => {
  const [saveState, setSaveState] = useState<boolean>(false);

  const saveDataToIndexDB = async (hadits: QuotesHadits) => {
    try {
      await saveDataByIdToIndexDB(hadits);
    } catch (error) {
      console.log(error);
    }
  };

  const deleteDataFromIndexDB = async (id: string) => {
    try {
      await deleteDataByIdFromIndexDB(id);
    } catch (error) {
      console.log(error);
    }
  };

  const toggleSave = () => {
    if (saveState) {
      deleteDataFromIndexDB(hadits.id);
    } else saveDataToIndexDB(hadits);
    setSaveState(!saveState);
  };

  useEffect(() => {
    const checkDataFromIndexDB = async (id: string) => {
      try {
        const data = await loadDataByIdFromIndexDB(id);
        setSaveState(!!data);
      } catch (error) {
        console.log(error);
      }
    };

    if (hadits) checkDataFromIndexDB(hadits.id);
  }, [hadits]);

  return (
    <Button
      className={`duration-300 text-sm flex items-center font-bold`}
      icon={<SaveIcon topicId={1} isSaved={saveState} />}
      onClick={toggleSave}
      label={""}
    />
  );
};

export default SaveButton;
