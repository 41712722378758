import { useEffect } from "react";
import IllustrationPenjelajah from "../../../../assets/illustrations/illustration1White.png";
import IllustrationPencariMakna from "../../../../assets/illustrations/illustration2White.png";
import { ReactComponent as LoadingIcon } from "../../../../assets/LoadingIcon.svg";
import { useNavigate } from "react-router-dom";

interface TransitionPageProps {
  roleTitle: string;
  isShow: boolean;
  pathLink: string;
}

const TransitionPage = ({
  isShow,
  roleTitle,
  pathLink,
}: TransitionPageProps) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (isShow) {
      const movePageTimeout = setTimeout(() => {
        navigate(pathLink);
      }, 750);

      // cleanup function
      return () => {
        clearTimeout(movePageTimeout);
      };
    }
  });

  return (
    <div
      className={`${
        isShow ? "translate-x-[0%]" : "translate-x-[-100%]"
      } w-full h-full z-20 right-0 duration-300 bg-[#4BBEFE] fixed top-0 flex justify-center items-center flex-col space-y-14`}
    >
      <h1 className="font-bold text-center text-2xl">{roleTitle}</h1>
      <img
        className="w-80"
        src={
          roleTitle === "Penjelajah"
            ? IllustrationPenjelajah
            : IllustrationPencariMakna
        }
        alt=""
      />
      <LoadingIcon className="w-12 animate-spin" />
    </div>
  );
};

export default TransitionPage;
