import SaveButton from "./save button/SaveButton";
import { QuotesHadits } from "../../Quotes";
import { ReactComponent as LeftArrowIcon } from "../../../../assets/leftArrowIcon.svg";
import { ReactComponent as ShareIcon } from "../../../../assets/ShareIcon.svg";

interface BottomNavigationProps {
  hadits: QuotesHadits;
  currentCounter: number;
  handleShare: () => void;
  counterIncrement: () => void;
  counterDecrement: () => void;
}

const BottomNavigation = ({
  hadits,
  currentCounter,
  handleShare,
  counterIncrement,
  counterDecrement,
}: BottomNavigationProps) => {
  return (
    <div className="fixed left-1/2 translate-x-[-50%] bottom-[-2.5rem] w-full flex justify-around space-x-5 md:space-x-44 mt-24 pb-20">
      <button
        disabled={!currentCounter}
        onClick={counterDecrement}
        className={`${
          !currentCounter ? "opacity-0" : "opacity-100"
        } duration-300 bg-[#41C9E2] rounded-full px-4 hover:bg-[#199AE1]`}
      >
        <LeftArrowIcon className="w-2.5" />
      </button>

      <div className="flex justify-between space-x-2.5 items-center">
        <button
          onClick={handleShare}
          className="flex justify-center items-center duration-300 px-5 py-2"
        >
          <ShareIcon className="w-[24px] mb-[-7px]" />
        </button>
        <SaveButton hadits={hadits} />
      </div>

      <button
        onClick={counterIncrement}
        className="opacity-100 duration-300 rotate-180 bg-[#41C9E2] rounded-full px-4 hover:bg-[#199AE1]"
      >
        <LeftArrowIcon className="w-2.5" />
      </button>
    </div>
  );
};

export default BottomNavigation;
