import { LazyLoadImage } from "react-lazy-load-image-component";

interface IllustrationBoxProps {
  ilustrasi: string;
}

const IllustrationBox = ({ ilustrasi }: IllustrationBoxProps) => {
  return (
    <div className="mb-3 bg-opacity-10 ">
      <LazyLoadImage
        alt="ilustrasi"
        className="w-[15rem] h-[7.5rem] sm:w-64 sm:h-48 lg:w-64 lg:h-48 m-auto object-contain"
        src={require(`../../../../assets/illustrations/${ilustrasi}.png`)}
        effect="opacity"
      />
      <div className="bg-[#41C9E2] bg-opacity-10 w-full h-24 mt-[-6rem] lg:h-40 lg:mt-[-10rem] rounded-2xl"></div>
    </div>
  );
};

export default IllustrationBox;
