import IllustrationBox from "../card mission/IllustrationBox";

interface CardSupportProps {
  illustration: string;
  title: string;
  button?: React.ReactNode;
}

const CardSupport = ({ title, button, illustration }: CardSupportProps) => {
  return (
    <div className="topics self-stretch	px-4 sm:px-6 pt-6 pb-6 w-11/12 sm:w-[20rem] lg:w-[26rem] rounded-3xl bg-white drop-shadow-secondary-blue sm:mx-4 mt-6">
      {/* illustration */}
      <IllustrationBox ilustrasi={illustration} />
      <h2 className="text-center text-[17px] text-secondary-black font-semibold pt-2 w-8/12 mx-auto">
        {title}
      </h2>
      {button}
    </div>
  );
};

export default CardSupport;
