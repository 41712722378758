import { BrowserRouter } from "react-router-dom";
import "./App.css";
import ScrollWrapper from "./components/ScrollWrapper";
import ListRoutes from "./components/ListRoutes";

const App = () => {
  return (
    <BrowserRouter>
      <ScrollWrapper>
        <ListRoutes />
      </ScrollWrapper>
    </BrowserRouter>
  );
};

export default App;
