import EmptyState from "../../components/ui/EmptyState";
import Navigation from "../ExploreTopics/components/navigation/Navigation";

const NotFound = () => {
  return (
    <div className="overflow-x-hidden">
      <Navigation />
      <div className="content m-[1rem] sm:m-8 mt-[8rem] md:mt-[6.5rem]">
        <EmptyState
          illustration="emptyState2"
          text="Halaman tidak ditemukan!"
        />
      </div>
    </div>
  );
};

export default NotFound;
