import Dexie from "dexie";
import { Topic } from "../variable interface/Topic";

const db = new Dexie("Havl Database");

export const savedTopicsDB = () => {
  db.version(1).stores({
    savedTopicsDB: "id, judul, kategori, ilustrasi, nomorTopik, hadits",
  });
  return db;
};

export const loadTopicByIdFromIndexDB = async (id: string) => {
  try {
    const data = await db.table("savedTopicsDB").get(id);
    return data;
  } catch (error) {
    throw error;
  }
};

export const deleteTopicByIdFromIndexDB = async (id: string) => {
  try {
    await db.table("savedTopicsDB").delete(id);
  } catch (error) {
    throw error;
  }
};

export const saveTopicToIndexDB = async (topic: Topic) => {
  try {
    await db.table("savedTopicsDB").add(topic);
  } catch (error) {
    throw error;
  }
};
