const TentangHadits = () => {
  return (
    <div className="text-center flex justify-center items-center mt-24 mb-12">
      <h2 className="text-2xl font-bold text-primary-black w-[40rem] leading-10">
        Hadits membantu meningkatkan kualitas diri. Dengan menerapkannya,
        Indonesia bisa membangun SDM yang unggul.
      </h2>
    </div>
  );
};

export default TentangHadits;
